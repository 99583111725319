import React from "react"
import "../sass/main.scss"
import {
  Layout,
  SEO,
  HeadSection,
  Scrollable,
  Image,
  Menu,
} from "../components"
import { Row, Col, Container } from "react-bootstrap"
import Client from "../model/Client"
import { MenuLinks } from "../model/Links"

interface CaseStudyProps {
  pageContext: { client: Client }
}

const ClientsPage = (props: CaseStudyProps) => {
  const { pageContext } = props
  const { client } = pageContext
  return (
    <Layout step={3}>
      <SEO
        title={client.seo_meta_title}
        description={client.seo_meta_description}
        twitterTitle={client.seo_twitter_title}
        twitterDescription={client.seo_twitter_description}
        openGraphTitle={client.seo_og_title}
        openGraphDescription={client.seo_og_description}
      />
      <section id="case-study-header" className="mb-40">
        <Container>
          <Row className="justify-content-center">
            <Col className="d-none d-lg-block" lg="4">
              <div className="st-block st-block--head">
                <Menu links={MenuLinks} id="content-menu" />
              </div>
            </Col>
            {client.detail_description ? (
              <Col xs="3" sm="4" md="5" lg="4">
                <div
                  className="st-block st-block--head"
                  dangerouslySetInnerHTML={{
                    __html: client.detail_title + client.detail_subtitle,
                  }}
                ></div>
              </Col>
            ) : (
              <Col xs="3" sm="8" md="10" lg="8">
                <div
                  className="st-block st-block--head"
                  dangerouslySetInnerHTML={{
                    __html: client.detail_title + client.detail_subtitle,
                  }}
                ></div>
              </Col>
            )}
            {client.detail_description ? (
              <Col xs="3" sm="4" md="5" lg="4" className="z-index-1001">
                <div
                  className="st-block st-block--head st-block--head-xs-0 text-inherit"
                  dangerouslySetInnerHTML={{
                    __html: client.detail_description,
                  }}
                ></div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </section>
      <section id="case-study-detail" className="pb-75">
        <Container className="position-relative">
          {client.detail_client ? (
            <Row className="case-study__row">
              <Col xs="3" sm="2" md="2" lg="3" xl="3" className="offset-lg-1">
                <h3 className="case-study__title">Client</h3>
              </Col>
              <Col
                xs="3"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                className="case-study__content"
                dangerouslySetInnerHTML={{ __html: client.detail_client }}
              ></Col>
            </Row>
          ) : null}
          <Row className="case-study__row">
            <Col xs="3" sm="2" md="2" lg="3" xl="3" className="offset-lg-1">
              <h3 className="case-study__title">Challenge</h3>
            </Col>
            <Col
              xs="3"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              className="case-study__content"
              dangerouslySetInnerHTML={{ __html: client.detail_challenge }}
            ></Col>
          </Row>
          <div className="col-2 case-study__logo-area d-none d-md-block">
            <div className="st-block case-study__logo">
              <Image
                path={client.logo}
                isSquare={false}
                alt={"Client | " + client.title}
              />
            </div>
          </div>
        </Container>
        {client.client_outcomes && client.client_outcomes.length > 0
          ? [
              <Container className="d-none d-sm-block" key="outcomes-sm">
                <Row className="case-study__row">
                  <Col
                    xs="3"
                    sm="2"
                    md="2"
                    lg="3"
                    xl="3"
                    className="offset-lg-1"
                  >
                    <h3 className="case-study__title">Outcomes</h3>
                  </Col>
                  {client.client_outcomes
                    .sort((one, two) => (one.order > two.order ? -1 : 1))
                    .map((outcome, index) => (
                      <Col
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                        key={index}
                        className={
                          index !== 0 && index % 3 === 0
                            ? "offset-sm-2 offset-md-2 offset-lg-4 offset-xl-4"
                            : ""
                        }
                      >
                        <div className="st-block outcome">
                          <h2 className="outcome__title">{outcome.title}</h2>
                          <h3 className="outcome__description">
                            {outcome.description}
                          </h3>
                        </div>
                      </Col>
                    ))}
                </Row>
              </Container>,
              <Container fluid className="d-sm-none" key="outcomes-xs">
                <Row className="case-study__row justify-content-center">
                  <Col
                    xs="3"
                    sm="2"
                    md="2"
                    lg="3"
                    xl="3"
                    className="offset-lg-1 container-max"
                  >
                    <h3 className="case-study__title">Outcomes</h3>
                  </Col>
                  <Col xs="3">
                    <Scrollable
                      section="#case-study-detail"
                      itemSelector=".outcome__item"
                    >
                      <Row
                        className="outcome__items st-scroll"
                        style={{
                          height: 3 * 133,
                        }}
                      >
                        <div
                          className="scroll-padding"
                          style={{
                            flexBasis: 3 * 133,
                          }}
                        />
                        {client.client_outcomes.map((outcome, index) => (
                          <div className="outcome__item" key={index}>
                            <div className="st-block">
                              <h2 className="outcome__title">
                                {outcome.title}
                              </h2>
                              <h3 className="outcome__description">
                                {outcome.description}
                              </h3>
                            </div>
                          </div>
                        ))}
                        <div
                          className="scroll-padding-right"
                          style={{
                            flexBasis: 3 * 133,
                          }}
                        />
                      </Row>
                    </Scrollable>
                  </Col>
                </Row>
              </Container>,
            ]
          : null}
        <Container>
          <Row className="case-study__row">
            <Col xs="3" sm="2" md="2" lg="3" xl="3" className="offset-lg-1">
              <h3 className="case-study__title">Solution</h3>
            </Col>
            <Col
              xs="3"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              className="case-study__content"
              dangerouslySetInnerHTML={{ __html: client.detail_solution }}
            ></Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ClientsPage
